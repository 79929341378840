$(document).ready(function () {
    frappe.ui.form.on("Lead", {
        refresh(frm) {
            setTimeout(() => {
                frm.page.remove_inner_button(__('Cotización'), __('Create'));
            }, 200);
            $(".form-dashboard").hide();
        }
    });

    frappe.ui.form.on("Opportunity", {
        custom_plan_guardian(frm) {
            frm.set_df_property('gestón_plan_guardian', 'hidden', 1)
            if (frm.doc.custom_plan_guardian == 1) {
                frm.set_df_property('gestón_plan_guardian', 'hidden', 0)
            }
        },
        custom_tipo_de_cliente(frm) {
            if (frm.doc.custom_tipo_de_cliente == "Propietario") {
                opciones_cliente_propietario(frm);
            }
            if (frm.doc.custom_tipo_de_cliente == "Arrendatario") {
                opciones_cliente_arrendatario(frm);
            }
        },

        refresh: function (frm) {
            setTimeout(() => {
                frm.page.remove_inner_button(__('Cotización'), __('Create'));
            }, 200);


            if (frm.doc.custom_contrato_de_arrendamiento === undefined) {
                obtener_contrato_arrendamiento(frm)

            }
            if (frm.doc.custom_tipo_de_cliente == "Propietario") {
                opciones_cliente_propietario(frm);
            }
            if (frm.doc.custom_tipo_de_cliente == "Arrendatario") {
                opciones_cliente_arrendatario(frm);
            }
        },

        custom_enviar_inmuebles: function (frm) {
            if (frm.doc.custom_inmuebles === undefined || frm.doc.custom_inmuebles.length == 0) {
                frappe.msgprint("No hay inmuebles registrados en la lista, seleccione como mínimo un inmueble antes de continuar.")
            } else {
                const inmuebles = []
                for (let i = 0; i < frm.doc.custom_inmuebles.length; i++) {
                    inmuebles.push(frm.doc.custom_inmuebles[i].inmueble)
                }

                frappe.model.get_value('Lead', frm.doc.party_name, '*', function (rp) {
                    frappe.call({
                        method:
                            "inmobiliaria.plantilla_oportunidad.correo_arrendatario_oportunidad",
                        args: {
                            id_inmuebles: JSON.stringify(inmuebles),
                            correo_arrendatario: rp.email_id,
                            nombre_arrendatario: rp.lead_name,
                        },
                        callback: function (rp) {
                            if (!rp.exc) {
                                frappe.msgprint("Inmuebles enviados correctamente", "Enviar inmuebles")
                            }

                        }
                    })

                })
            }
        },
        custom_buscar: function (frm) {
            let dialog = new frappe.ui.Dialog({
                size: "large",
                title: "Buscar Inmueble",
                fields: [
                    {
                        fieldname: "habitaciones",
                        label: "Cantidad máxima de habitaciones",
                        fieldtype: "Int",
                        default: frm.doc.custom_cantidad_habitaciones
                    },
                    {
                        fieldname: "banos",
                        label: "Cantidad máxima de baños",
                        fieldtype: "Int",
                        default: frm.doc.custom_cantidad_banos
                    },
                    {
                        fieldname: "area_general",
                        label: "Área general",
                        fieldtype: "Float",
                        default: frm.doc.custom_area_general
                    },
                    {
                        fieldname: "ciudad",
                        label: "Ciudad",
                        fieldtype: "Data",
                        default: frm.doc.custom_ciudad
                    },
                    {
                        fieldname: "salto_columna",
                        label: "",
                        fieldtype: "Column Break"
                    },
                    {
                        fieldname: "zona",
                        label: "Barrio",
                        fieldtype: "Data",
                        default: frm.doc.custom_zona
                    },
                    {
                        fieldname: "precio_maximo",
                        label: "Precio máximo",
                        fieldtype: "Currency",
                        default: frm.doc.custom_precio_maximo
                    },
                    {
                        fieldname: "tipo_inmueble",
                        label: "Tipo de inmueble",
                        fieldtype: "Select",
                        options:
                            ["", "Casa", "Apartamento", "Local comercial", "Oficina", "Lote", "Lote Comercial", "Finca", "Bodega", "Chalet", "Casa de Campo", "Hoteles", "Finca - Hoteles", "Apartaestudio", "Consultorio", "Edificio", "Lote de Playa", "Hostal", "Condominio", "Dúplex", "Atico", "Bungalow", "Galpon Industrial", "Casa de Playa", "Piso", "Garaje", "Cortijo", "Cabañas", "Isla", "Nave Industrial", "Campos", "Chacras y Quintas", "Terreno"],
                        default: frm.doc.custom_tipo_inmueble
                    },
                    {
                        fieldname: "renta",
                        label: "En Renta",
                        fieldtype: "Check",
                        default: "1"
                    },
                    {
                        fieldname: "venta",
                        label: "En Venta",
                        fieldtype: "Check",
                        default: "0"
                    },
                    {
                        fieldname: "salto_seccion",
                        label: "",
                        fieldtype: "Section Break"
                    },
                    { fieldname: "tabla", label: "", fieldtype: "HTML" }
                ],

                primary_action_label: "Buscar",
                primary_action: function () {
                    let values = dialog.get_values();
                    let not_in_name = "";
                    let tbl = frm.doc.custom_inmuebles || [];
                    let i = tbl.length;
                    while (i--) {
                        not_in_name =
                            not_in_name + "," + frm.doc.custom_inmuebles[i].inmueble;
                    }
                    let wrapper = dialog.fields_dict["tabla"].$wrapper;
                    let table = $(`
                        <table  class="table table-striped table-condensed">
                            <thead>
                                <tr>
                                    <th style="text-align:center">Selección</th>
                                    <th style="text-align:center">Titulo</th>
                                    <th style="text-align:center">Dirección</th>
                                    <th style="text-align:center">Habitaciones</th>
                                    <th style="text-align:right">Canon</th>
                                    <th style="text-align:right">Precio de Venta</th>
                                </tr>
                            </thead>
                            <tbody></tbody>
                        </table>
                    `);

                    wrapper.html(table);

                    var objeto_filtro = {
                        bedrooms: ['<=', `${values["habitaciones"]}`, false],
                        bathrooms: ['<=', `${values["banos"]}`, false],
                        built_area: ['<=', `${values["area_general"]}`, false],
                        property_type: ["=", `${values["tipo_inmueble"]}`, false],
                        status_on_page_name: ["in", ['Activo', 'Destacado'], false],
                        rent_price: ['<=', `${values["precio_maximo"]}`, false],
                        city_name: ["like", `%${values["ciudad"]}%`, false],
                        zone_name: ["like", `%${values["zona"]}%`, false],
                        for_rent: ["=", `${values["renta"]}`, false],
                        for_sale: ["=", `${values["venta"]}`, false],
                    }
                    var claves_filtro = Object.keys(objeto_filtro);

                    for (let f = 0; f < claves_filtro.length; f++) {
                        let elemento = claves_filtro[f];

                        if ((objeto_filtro[elemento][1] === "%undefined%") || (objeto_filtro[elemento][1] === "undefined")) {
                            delete objeto_filtro[elemento]
                            console.log(objeto_filtro[elemento]);
                        }
                    }
                    console.log(objeto_filtro);

                    frappe.db
                        .get_list("Inmueble", {
                            fields: "*",
                            filters: objeto_filtro
                        })
                        .then((records) => {
                            if (records) {
                                table.find("tbody").html("");

                                records.forEach((record) => {
                                    let tr = $(`
                                    <tr>
                                        <td style="text-align:center">
                                            <input name="${record.name}" type="checkbox" value="${record.name}" on_change(this)>
                                        </td>
                                        <td style="text-align:center">${record.property_name}</td>
                                        <td style="text-align:center">${record.address}</td>
                                        <td style="text-align:center">${record.bedrooms}</td>
                                        <td style="text-align:right">${record.rent_price_formated}</td>
                                        <td style="text-align:right">${record.sale_price_formated}</td>
                                    </tr>
                                `);

                                    tr.find("[type=checkbox]").change(() => {
                                        let checked =
                                            $(
                                                'input[name="' +
                                                record.name +
                                                '"]:checked'
                                            ).length > 0;
                                        if (checked) {
                                            frm.add_child("custom_inmuebles", {
                                                inmueble: record.name,
                                                tipo_predio: record.property_type,
                                                ciudad: record.city_name,
                                                zona: record.zone_name,
                                                canon_arrendamiento: record.rent_price_formated,
                                                precio_venta: record.sale_price_formated,
                                                crear_evento: 0
                                            });
                                            frm.save()
                                        } else {
                                            let tbl = frm.doc.custom_inmuebles || [];
                                            let i = tbl.length;
                                            while (i--) {
                                                if (
                                                    frm.doc.custom_inmuebles[i]
                                                        .inmueble == record.name
                                                ) {
                                                    cur_frm
                                                        .get_field("custom_inmuebles")
                                                        .grid.grid_rows[
                                                        i
                                                    ].remove();
                                                }
                                            }
                                        }
                                        frm.refresh_field("custom_inmuebles");
                                    });
                                    table.find("tbody").append(tr);
                                });
                            }
                        });
                }
            });
            dialog.show();
        },

        // Evento para agendar una cita
        custom_agendar_cita: function (frm) {
            var lista_inmuebles = [];

            if (frm.doc.custom_inmuebles === undefined) {
                lista_inmuebles = ["No hay inmuebles registrados en la lista, seleccione como mínimo un inmueble antes de continuar."]
            } else {
                for (let i = 0; i < frm.doc.custom_inmuebles.length; i++) {
                    let zona = frm.doc.custom_inmuebles[i].zona
                    let ciudad = frm.doc.custom_inmuebles[i].ciudad
                    let tipo_predio = frm.doc.custom_inmuebles[i].tipo_predio
                    let name = frm.doc.custom_inmuebles[i].inmueble


                    if (zona.length > 0) {
                        lista_inmuebles.push(ciudad + " - " + tipo_predio + " - " + zona + " - " + name);
                    } else {
                        lista_inmuebles.push(ciudad + " - " + tipo_predio + " - " + name);
                    }

                }
            }
            var d = new frappe.ui.Dialog({
                title: "Agendar Cita",
                fields: [
                    {
                        fieldname: "categoria",
                        fieldtype: "Select",
                        label: "Categoría de Evento",
                        options: [
                            "Event",
                            "Meeting",
                            "Call",
                            "Sent/Received Email",
                            "Other"
                        ],
                        default: "Meeting"
                    },
                    {
                        fieldname: "tipo",
                        fieldtype: "Select",
                        label: "Tipo de Evento",
                        options: ["Private", "Public"]
                    },
                    {
                        fieldname: "fecha_hora",
                        fieldtype: "Datetime",
                        label: "Fecha y Hora"
                    },
                    {
                        fieldname: "enviar_correo",
                        fieldtype: "Check",
                        label: "Enviar Correo"
                    },
                    {
                        fieldname: "asunto",
                        fieldtype: "Data",
                        label: "Asunto",
                        hidden: 1
                    },
                    {
                        fieldname: "asignado_a",
                        fieldtype: "Link",
                        label: "Asignado a",
                        options: "User",
                        default: frappe.session.user
                    },
                    {
                        fieldname: "crear_evento",
                        fieldtype: "Select",
                        label: "Crear Evento a Inmueble",
                        options: lista_inmuebles
                    },
                    {
                        fieldname: "descripcion",
                        fieldtype: "Text Editor",
                        label: "Descripción",
                        hidden: 1
                    }
                ],
                primary_action: function () {
                    let dict = d.get_values();

                    var name_inmueble = dict["crear_evento"].split(" - ")
                    name_inmueble = name_inmueble[name_inmueble.length - 1]

                    if (
                        !dict["crear_evento"] ||
                        !dict["categoria"] ||
                        !dict["tipo"] ||
                        !dict["fecha_hora"] ||
                        !dict["asignado_a"]
                    ) {
                        frappe.show_alert("Por favor, llene todos los campos");
                        return;
                    }

                    d.hide();

                    let cliente = null;
                    frappe
                        .call({
                            method: "frappe.client.get_list",
                            async: false,
                            args: {
                                doctype: frm.doc.opportunity_from,
                                filters: {
                                    name: frm.doc.party_name
                                },
                                fields: "*"
                            }
                        })
                        .then((rp) => {
                            if (rp.message.length > 0) {
                                cliente = rp.message[0];
                            }
                        });
                    let asunto = "";
                    let descripcion = ``;
                    let destinatarios = "";
                    destinatarios = dict["asignado_a"] + "," + cliente.email_id;

                    let name = name_inmueble;

                    frappe
                        .call({
                            method: "frappe.client.get_list",
                            async: false,
                            args: {
                                doctype: "Inmueble",
                                filters: {
                                    name: name
                                },
                                fields: "address, property_type, zone_name"
                            }
                        })
                        .then((rp) => {
                            if (rp.message.length > 0) {
                                descripcion += `<b>Lugar:</b> ${rp.message[0].address}<br />`;
                                asunto =
                                    "Visita " +
                                    rp.message[0].property_type +
                                    " en " +
                                    rp.message[0].zone_name;
                            }
                        });

                    //Se deja las dos condiciones para evaluar por cada  condiciónal

                    descripcion += `
                                        <b>Agente comercial:</b> ${frappe.session.user}<br />
                                        <b>Interesado:</b> ${cliente.lead_name}<br />`;

                    if (!destinatarios) {
                        frappe.msgprint(
                            "Este cliente no tiene correos asignados"
                        );
                    }

                    let event_participants = [];

                    destinatarios.split(",").forEach((correo, index) => {
                        correo = correo.trim();

                        if (!correo) {
                            return;
                        }

                        let contact_name = null;

                        frappe
                            .call({
                                method: "frappe.client.get_list",
                                async: false,
                                args: {
                                    doctype: "Contact",
                                    filters: {
                                        email_id: correo
                                    },
                                    fields: "*"
                                }
                            })
                            .then((rp) => {
                                if (rp.message.length > 0) {
                                    contact_name = rp.message[0].name;
                                }
                            });

                        if (!contact_name) {
                            frappe
                                .call({
                                    method: "frappe.client.insert",
                                    args: {
                                        doc: {
                                            doctype: "Contact",
                                            first_name: correo,
                                            email_id: correo,
                                            email_ids: [
                                                {
                                                    email_id: correo,
                                                    is_primary: 1,
                                                    idx: 0,
                                                    __checked: 1
                                                }
                                            ]
                                        }
                                    },
                                    async: false
                                })
                                .then((rp) => {
                                    if (rp.message) {
                                        contact_name = rp.message.name;
                                    }
                                });
                        }

                        event_participants.push({
                            reference_doctype: "Contact",
                            reference_docname: contact_name,
                            idx: index,
                            __checked: 1
                        });
                    });

                    frappe.call({
                        method: "frappe.client.insert",
                        args: {
                            doc: {
                                doctype: "Event",
                                send_reminder: 1,
                                event_category: dict["categoria"],
                                event_type: dict["tipo"],
                                status: "Open",
                                subject: asunto,
                                starts_on: dict["fecha_hora"],
                                description: descripcion,
                                event_participants: event_participants,
                                doctype_caso: frm.doc.doctype,
                                id_caso: frm.doc.name
                            }
                        },
                        callback: function () {
                            frappe.msgprint("Evento creado correctamente");
                            frm.refresh();

                            if (dict["enviar_correo"] == 1) {
                                frappe.call({
                                    method:
                                        "inmobiliaria.rpc.recordatorio_evento",
                                    args: {
                                        destinatarios: destinatarios,
                                        asunto:
                                            "Nuevo evento creado en el sistema: " +
                                            dict["asunto"] +
                                            ", Para la fecha: " +
                                            dict["fecha_hora"],
                                        mensaje:
                                            descripcion + dict["descripcion"]
                                    },
                                    callback: function (rp) {
                                        if (rp.message.ok) {
                                            frappe.msgprint(
                                                "Correo enviado correctamente"
                                            );
                                        } else {
                                            frappe.msgprint(
                                                "Error al enviar el correo: " +
                                                rp.message.error
                                            );
                                        }
                                    }
                                });
                            }
                        }
                    });
                }
            });

            d.show();
        }
    }
    );

    function obtener_contrato_arrendamiento(frm) {
        frappe.model.get_value('Contratos de Arrendamiento', { "oportunidad": frm.doc.name }, 'name', function (rp) {
            if (rp) {
                frm.doc.custom_contrato_de_arrendamiento = rp.name
                frm.refresh_field("custom_contrato_de_arrendamiento")
            }
        });

    }

    function opciones_cliente_propietario(frm) {
        frm.page.remove_inner_button(__('Contrato de arrendamiento'), __('Create'));
        if (frm.doc.custom_inmueble != undefined && frm.doc.custom_contrato_de_mandato === undefined) {
            frm.add_custom_button(__('Crear contrato de mandato'), function () {
                crear_contrato_mandato(frm)
            }, __('Create'));
        }

        if (frm.doc.custom_inmueble === "" || frm.doc.custom_inmueble === undefined || !frm.doc.custom_inmueble_wasi) {
            frm.add_custom_button(__('Crear inmueble'), function () {
                crear_inmueble_formulario(frm)
            }, __('Create'));
        }
    }
    function crear_inmueble_formulario(frm) {
        var d = new frappe.ui.Dialog({
            title: "Crear inmueble",
            fields: [


                {
                    fieldname: "propietario",
                    fieldtype: "Link",
                    label: "Propietario",
                    options: "Customer",
                    reqd: 1

                },
                {
                    fieldname: "direccion",
                    fieldtype: "Data",
                    label: "Dirección del inmueble",
                    reqd: 1
                },
                {
                    fieldname: "departamento",
                    fieldtype: "Data",
                    label: "Departamento",
                    reqd: 1
                },
                {
                    fieldname: "ciudad",
                    fieldtype: "Data",
                    label: "Ciudad",
                    reqd: 1
                },
                {
                    fieldname: "zona",
                    fieldtype: "Data",
                    label: "Zona",
                    reqd: 1
                },
                {
                    fieldname: "descripcion_general",
                    fieldtype: "Text Editor",
                    label: "Descripción General",
                },
                {
                    fieldname: "tipo_predio",
                    fieldtype: "Select",
                    label: "Tipo de predio",
                    options: [
                        "",
                        "Casa",
                        "Casa Conjunto Cerrado",
                        "Apartamento",
                        "Apartaestudio",
                        "Oficina",
                        "Bodega",
                        "Local",
                        "Consultorio",
                        "Lote",
                        "Lote Urbano",
                    ],
                    reqd: 1
                },
                {
                    fieldname: "valor_canon",
                    fieldtype: "Currency",
                    label: "Valor canon",
                    reqd: 1
                },
                {
                    fieldname: "valor_iva_canon",
                    fieldtype: "Currency",
                    label: "Valor IVA canon"
                },
                {
                    fieldname: "tiene_administracion",
                    fieldtype: "Check",
                    label: "¿Tiene administración?"
                },
                {
                    fieldname: "valor_administracion",
                    fieldtype: "Currency",
                    label: "Valor administración",
                    depends_on: "eval:doc.tiene_administracion==1",
                }
            ],
            primary_action: async function () {
                var data = d.get_values()
                data['oportunidad'] = frm.doc.name
                await crear_inmueble_proceso(data, frm);

                if (frm.doc.custom_inmueble) {
                    data['inmueble_administrativo'] = frm.doc.custom_inmueble
                    await crear_inmueble_proceso(data, frm);
                }

                d.hide();
            }
        });
        d.show();
    }

    async function crear_inmueble_proceso(valores, frm) {
        var inmueble_comercial = []
        var inmueble_administrativo = []
        var tipo_predio = ({
            "Casa": "Casa",
            "Casa Conjunto Cerrado": "Edificio",
            "Apartamento": "Apartamento",
            "Apartaestudio": "Apartaestudio",
            "Oficina": "Oficina",
            "Bodega": "Bodega",
            "Local": "Local",
            "Consultorio": "Consultorio",
            "Lote": "Lote",
            "Lote Urbano": "Lote de Playa",
        });

        inmueble_administrativo.push({
            'doctype': "Inmuebles",
            'direccion': valores["direccion"],
            'ciudad': valores["ciudad"],
            'departamento': valores["departamento"],
            'localidad': valores["zona"],
            'barrio': valores["zona"],
            'pais': 'Colombia',
            'descripcion_general': valores["descripcion_general"],
            'tipo_predio': valores["tipo_predio"],
            'valor_canon': valores["valor_canon"],
            'valor_iva_canon': valores["valor_iva_canon"],
            'tiene_administracion': valores["tiene_administracion"],
            'valor_administracion': valores["valor_administracion"],
            'cliente_propietario': valores["propietario"],
            'oportunidad': valores['oportunidad'],
            'estado': "Libre",
        });

        inmueble_comercial.push({
            'doctype': 'Inmueble',
            'for_rent': 1,
            'rents_type_name': 'Cobro mensual',
            'availability_name': 'Disponible',
            'publish_on_map_name': 'No publicar',
            'property_condition_name': 'Usado',
            'property_name': valores['direccion'],
            'address': valores["direccion"],
            'country_name': 'Colombia',
            'city_name': valores["ciudad"],
            'region_name': valores["departamento"],
            'location_name': valores["zona"],
            'zone_name': valores["zona"],
            'comment': valores["descripcion_general"],
            'property_type': tipo_predio[valores["tipo_predio"]],
            'rent_price': valores["valor_canon"],
            'maintenance_fee': valores["valor_administracion"],
            'inmueble_administrativo': valores['inmueble_administrativo'],
            'status_on_page_name': "Activo",
        });

        let comercial_name = null;
        let administrativo_name = null;

        if (!frm.doc.custom_inmueble_wasi) {
            frappe.call({
                method: "frappe.client.insert",
                args: {
                    doc: inmueble_comercial[0],
                },
                async: false,
            }).then(async (rp) => {
                if (rp.message) {
                    comercial_name = rp.message.name;
                    frm.doc.custom_inmueble_wasi = rp.message.name;
                    frm.refresh_field("custom_inmueble_wasi");
                    inmueble_administrativo[0].inmueble_comercial = rp.message.name;
                    // await frappe.db.set_value("Opportunity", frm.doc.name, "custom_inmueble_wasi", rp.message.name);
                }
            })
        }

        if (!frm.doc.custom_inmueble) {
            frappe
            .call({
                method: "frappe.client.insert",
                args: {
                    doc: inmueble_administrativo[0],
                },
                async: false
            })
            .then(async (rp) => {
                if (rp.message) {
                    if (!frm.doc.custom_inmueble) {
                        administrativo_name = rp.message.name;
                        frm.doc.custom_inmueble = rp.message.name;
                        frm.refresh_field("custom_inmueble");
                        // await frappe.db.set_value("Opportunity", frm.doc.name, "custom_inmueble", rp.message.name);
                    }

                    frappe.msgprint("Inmueble creado correctamente.")
                }
            });
        }

        frm.dirty();
        await frm.save();

        setTimeout(async () => {
            if (administrativo_name) {
                await frappe.db.set_value("Opportunity", frm.doc.name, "custom_inmueble", administrativo_name);
            }

            if (comercial_name) {
                await frappe.db.set_value("Opportunity", frm.doc.name, "custom_inmueble_wasi", comercial_name);
            }

            if (administrativo_name && comercial_name) {
                frappe.call({
                    method:
                        "inmobiliaria.rpc.actualizar_inmueble_comercial_en_administrativo_single",
                    args: {
                        comercial_name: comercial_name,
                        administrativo_name: administrativo_name,
                    },
                    async: false,
                    callback: function (rp) {
                        console.log(rp);
                    }
                });
            }
        }, 1000);
    }

    function crear_contrato_mandato(frm) {
        var d = new frappe.ui.Dialog({
            title: "Crear contrato de mandato",
            fields: [
                {
                    fieldname: "cliente_propietario",
                    fieldtype: "Link",
                    label: "Propietario",
                    options: "Customer",
                    reqd: 1
                },
                {
                    fieldname: "fecha_inicio",
                    fieldtype: "Datetime",
                    label: "Fecha de Inicio Contrato",
                }
            ],
            primary_action: function () {
                let valores = d.get_values();
                frappe
                    .call({
                        method: "frappe.client.insert",
                        args: {
                            doc: {
                                doctype: "Contratos de Mandato",
                                cliente_propietario: valores["cliente_propietario"],
                                fecha_inicio: valores["fecha_inicio"],
                                oportunidad: frm.doc.name,
                            }
                        },
                        async: false
                    })
                    .then((rp) => {
                        if (rp.message) {
                            frm.page.remove_inner_button(__('Crear contrato de mandato'), __('Create'));
                            frm.doc.custom_contrato_de_mandato = rp.message.name
                            frm.refresh_field("custom_contrato_de_mandato")
                            frappe.msgprint("Contrato de mandato creado correctamente.")
                            frm.save()
                        }
                    });
            }
        });

        d.show();
    }


    function opciones_cliente_arrendatario(frm) {
        frm.page.remove_inner_button(__('Crear inmueble'), __('Create'));
        frm.page.remove_inner_button(__('Crear contrato de mandato'), __('Create'));
        if (frm.doc.custom_contrato_de_arrendamiento === "" || frm.doc.custom_contrato_de_arrendamiento === undefined) {
            frm.add_custom_button(__('Contrato de arrendamiento'), function () {
                crear_contrato_arrendamiento(frm);
            }, __('Create'));
        }
    }

    function crear_contrato_arrendamiento(frm) {
        var lista_inmuebles = [];
        var lead = frm.doc.party_name

        if (!frm.doc.custom_inmuebles || frm.doc.custom_inmuebles.length === 0) {
            lista_inmuebles = ["No hay inmuebles registrados en la lista, seleccione como mínimo un inmueble antes de continuar."]
        } else {
            for (let i = 0; i < frm.doc.custom_inmuebles.length; i++) {
                let zona = frm.doc.custom_inmuebles[i].zona
                let ciudad = frm.doc.custom_inmuebles[i].ciudad
                let tipo_predio = frm.doc.custom_inmuebles[i].tipo_predio
                let name = frm.doc.custom_inmuebles[i].inmueble
                let direccion = null;

                frappe
                    .call({
                        method: "frappe.client.get_list",
                        async: false,
                        args: {
                            doctype: "Inmueble",
                            filters: {
                                name: name
                            },
                            fields: ["address"],
                        }
                    })
                    .then((rp) => {
                        if (rp.message.length > 0) {
                            direccion = rp.message[0].address;
                        }
                    });

                if (zona.length > 0) {
                    if (direccion) {
                        lista_inmuebles.push(ciudad + " - " + tipo_predio + " - " + zona + " - " + direccion + " - " + name);
                    } else {
                        lista_inmuebles.push(ciudad + " - " + tipo_predio + " - " + zona + " - " + name);
                    }
                } else {
                    lista_inmuebles.push(ciudad + " - " + tipo_predio + " - " + name);
                }

            }
        }

        let dialog = new frappe.ui.Dialog({
            size: "large",
            title: "Contrato de arrendamiento",
            fields: [
                {
                    fieldname: "numero_documento",
                    label: "Documento de Identificación",
                    fieldtype: "Int",
                    reqd: 1,
                    onchange() {
                        if (this.value) {
                            frappe.model.get_value('Customer', { "tax_id": this.value }, '*', function (rp) {
                                if (rp) {
                                    dialog.fields_dict.nombre_completo.value = rp.name
                                    dialog.fields_dict.tipo_cliente.value = rp.customer_type
                                    dialog.fields_dict.tipo_documento.value = rp.tipo_documento
                                    dialog.fields_dict.territorio.value = rp.territory
                                    dialog.fields_dict.categoria_cliente.value = rp.customer_group

                                    dialog.fields_dict.nombre_completo.refresh()
                                    dialog.fields_dict.tipo_cliente.refresh()
                                    dialog.fields_dict.tipo_documento.refresh()
                                    dialog.fields_dict.territorio.refresh()
                                    dialog.fields_dict.categoria_cliente.refresh()
                                }
                            });
                        }
                    }
                },
                {
                    fieldname: "tipo_documento",
                    label: "Tipo de documento",
                    fieldtype: "Select",
                    options: [
                        "C.C.",
                        "C.E.",
                        "NIT",
                        "T.I.",
                        "PP",
                        "R.C.",
                        "D.E.",
                    ],
                    reqd: 1
                },

                {
                    fieldname: "nombre_completo",
                    label: "Nombre completo",
                    fieldtype: "Data",
                    reqd: 1,
                },
                {
                    fieldname: "tipo_cliente",
                    label: "Tipo",
                    fieldtype: "Select",
                    options: [
                        "Company",
                        "Individual",
                    ],
                    translatable: 1,
                    reqd: 1,

                },
                {
                    fieldname: "salto_columna",
                    label: "",
                    fieldtype: "Column Break",
                    reqd: 1,

                },
                {
                    fieldname: "territorio",
                    label: "Territorio",
                    fieldtype: "Link",
                    options: "Territory",
                    default: "Todos los Territorios",
                    reqd: 1,

                },
                {
                    fieldname: "categoria_cliente",
                    label: "Categoría de Cliente",
                    fieldtype: "Link",
                    options: "Customer Group",
                    default: "Todas las categorías de clientes",
                    reqd: 1,

                },
                {
                    fieldname: "inmueble_comercial",
                    label: "Inmueble a tomar por el arrendatario",
                    fieldtype: "Select",
                    options: lista_inmuebles,
                    reqd: 1,

                },
            ],
            primary_action_label: "Guardar",
            primary_action: function () {
                let data = dialog.get_values();
                var name_inmueble = data["inmueble_comercial"].split(" - ")
                data["inmueble_comercial"] = name_inmueble[name_inmueble.length - 1]
                data["lead"] = lead
                data['oportunidad'] = frm.doc.name

                frappe.model.get_value('Customer', { "tax_id": data["numero_documento"] }, '*', function (rp) {
                    if (rp === undefined) {
                        frappe
                            .call({
                                method: "frappe.client.insert",
                                args: {
                                    doc: {
                                        doctype: "Customer",
                                        customer_name: data["nombre_completo"],
                                        customer_type: data["tipo_cliente"],
                                        customer_group: data["categoria_cliente"],
                                        territory: data["territorio"],
                                        tax_id: data["numero_documento"],
                                        es_arrendatario: 1,
                                    }
                                },
                                async: true
                            })
                            .then((rp) => {
                                if (rp.message) {
                                    data["cliente"] = rp.message.name
                                }
                            });
                    }

                    frappe.call({
                        method:
                            "inmobiliaria.rpc.crear_contrato_arrendamiento_desde_oportunidad",
                        args: {
                            data: JSON.stringify(data),
                        },
                        async: true,
                        callback: function (rp) {
                            if (!rp.message.error) {
                                frappe.set_route("Form", "Contratos de Arrendamiento", rp.message.doctype_name);
                            } else {
                                frappe.msgprint({
                                    title: __('Notification'),
                                    indicator: 'orange',
                                    message: rp.message.mensaje_error
                                });
                            }
                        }
                    })
                });

            }
        });

        dialog.show();
    }
});
